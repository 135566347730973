<template>
  <div class="home">
    <div class="wrapper">
      <Nav :isFlexible="true" />
      <div class="banner">
        <el-carousel
          :height="clientHeight + 'px'"
          :pause-on-hover="false"
          indicator-position="none"
          :interval="5000"
          arrow="never"
          @change="bannerChange"
        >
          <el-carousel-item v-for="pic in picList" :key="pic.url">
            <el-image
              style="width: 100%; height: inherit"
              fit="cover"
              :src="this.PicBaseUrl + pic.url"
            >
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="slogan" :style="{ height: clientHeight + 'px' }">
        <transition name="slogan">
          <div class="slogan-content">
            <div class="slogan-title title">
              <p>全栈技术</p>
              <p>助力锂电负极</p>
              <div class="line"></div>
            </div>
            <div class="slogan-subtitle">
              <p>
                凭借自研设备、核心工艺配方、可视化数据分析、自建远程监控系统，在有效降低锂电负极核心材料（球形石墨）生产成本的同时大幅提升产品质量稳定性，并持续开发高附加值石墨制品，提高天然石墨综合利用效率
              </p>
            </div>
          </div>
        </transition>
      </div>
      <section id="device" class="section-dark">
        <div class="device">
          <div class="device-title title" data-inviewport>
            自研核心设备
            <div class="line"></div>
          </div>
          <div class="device-subtitle subtitle">
            不同于行业内常用的串联机设备，墨砾自研的并联批次生产系统，具有更加优越的性能；同时借助工程团队强大的设备研发能力，在原料预处理、自动化粉体检测方面都有持续性创新
          </div>
          <div class="device-detail-wrapper">
            <div
              v-for="device in devices"
              :key="device.name"
              class="device-detail"
              data-inviewport
            >
              <div class="inner">
                <i class="iconfont" :class="device.icon"></i>
                <div class="device-detail-content">
                  <div class="device-detail-title">{{ device.name }}</div>
                  <div class="device-detail-subtitle">
                    {{ device.remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="material">
        <div class="material">
          <div data-inviewport class="material-title title">
            新材料开发
            <div class="line"></div>
          </div>
          <div class="material-detail-wrapper">
            <div data-inviewport class="material-detail-image">
              <el-image
                style="width: 375px;"
                fit="cover"
                :src="this.PicBaseUrl + 'material-image2.png'"
              >
              </el-image>
            </div>
            <div class="material-detail">
              <div
                class="material-detail-content"
                v-for="material in materials"
                :key="material.name"
                data-inviewport
              >
                <div class="material-detail-content-small">
                  <div class="material-detail-icon">
                    <i class="iconfont" :class="material.icon"></i>
                  </div>
                  <div class="material-detail-title">{{ material.name }}</div>
                  <div class="material-detail-subtitle">
                    {{ material.remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="vistural" class="section-dark">
        <div class="vistural">
          <div class="vistural-title title" data-inviewport>
            数字化运营
            <div class="line"></div>
          </div>
          <div class="vistural-subtitle subtitle">
            通过自主搭建的物联网平台，将产线所有设备接入网络，极大地提高了现场运营的便利性和可靠性；同时所有设备运转数据实时上云，便于总部工程师远程监控与协助
          </div>
          <div class="vistural-detail-wrapper">
            <div
              v-for="v in visturals"
              :key="v.name"
              class="vistural-detail"
              data-inviewport
            >
              <div class="inner">
                <div class="vistural-detail-content">
                  <div class="vistural-detail-image">
                    <el-image
                      style="width:100%; height: 200px; border-radius: 6px"
                      fit="cover"
                      :src="this.PicBaseUrl + v.image"
                    >
                    </el-image>
                  </div>
                  <div class="vistural-detail-title">{{ v.name }}</div>
                  <div class="vistural-detail-subtitle">
                    {{ v.remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="productline">
        <div class="productline">
          <div class="productline-title" data-inviewport>
            <p>大幅提高球化石墨产线效益</p>
          </div>
          <div class="productline-detail-wrapper">
            <div
              v-for="pl in productlines"
              :key="pl.id"
              class="productline-detail"
              data-inviewport
            >
              <div class="inner">
                <el-icon>
                  <Select />
                </el-icon>
                <div class="productline-info">
                  <div
                    data-inviewport
                    v-if="pl.numeric"
                    class="productline-info-title"
                  >
                    {{
                      pl.numeric_display.toFixed(0) +
                      (pl.numeric2
                        ? "/" + pl.numeric_display2.toFixed(0)
                        : "") +
                      pl.non_numeric
                    }}
                  </div>
                  <div v-else class="productline-info-title">
                    {{ pl.non_numeric }}
                  </div>
                  <div class="productline-info-detail">{{ pl.detail }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <!-- <div class="test">
        <p>{{ tweenedNumber }}</p>
      </div>
      <button v-on:click="bannerShow = !bannerShow">Toggle</button>
      <transition name="slide-fade">
        <p v-if="show">hello</p>
      </transition> -->
      <!-- <div style="height: 1000px"></div> -->
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

// import { nextTick } from "vue";
import { Select } from "@element-plus/icons";

export default {
  name: "Home",
  components: {
    // Fold,
    Select,
    // Setting,
    // Tools,
    Nav,
    Footer,
  },
  mounted() {
    // window.addEventListener("scroll", this.scrollToTop);
    this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
    window.addEventListener("resize", () => {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      // console.log(this.clientHeight);
    });

    // 是否在可视范围内(需要再研究下)
    const inViewport = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // if (!("is-inViewport" in entry.target.className.split(" "))) {
          entry.target.classList.add("is-inViewport");
          this.raiseNumeric(entry);
          // }
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      // threshold: 0.8
    }; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

    // Attach observer to every [data-inviewport] element:
    const ELs_inViewport = document.querySelectorAll("[data-inviewport]");
    ELs_inViewport.forEach((EL) => {
      Obs.observe(EL, obsOptions);
    });
  },
  data() {
    return {
      // activeIndex: "1",
      // activeIndex2: "1",
      PicBaseUrl: "//image.nexmaterials.com.cn/",
      picList: [
        { url: "bg-blur-1.jpg", isActive: true },
        { url: "bg-blur-2.jpg", isActive: false },
        { url: "bg-blur-3.jpg", isActive: false },
      ],
      bannerH: 600,
      bannerShow: false,
      clientHeight: 0,
      devices: [
        {
          name: "原料预处理",
          icon: "icon-cangchucangku",
          remark:
            "自研的预处理系统，可大幅降低不同批次之间原材料的粒度差异对最终产品质量的影响",
        },
        {
          name: "石墨球形化",
          icon: "icon-guanli",
          remark:
            "不同于以往的串联式生产设备，创新的批次式天然石墨球化产线，显著提高了产品质量和稳定性",
        },
        {
          name: "粉体自动检测",
          icon: "icon-shujujianguan",
          remark:
            "自动化粉体分散设备，替代了人工制备检测样品的流程，大大提高检测效率和一致性",
        },
        {
          name: "高附加值副产品",
          icon: "icon-jiazhi",
          remark:
            "其它各类自研设备通过对尾料进行再粉碎、再分级等操作，极大地提高了副产品附加值",
        },
      ],
      materials: [
        {
          name: "人才储备",
          icon: "icon-bumen",
          remark:
            "不同于传统设备厂，墨砾团队一贯重视研发团队对于材料科学的深刻理解，以市场需求引导设备及工艺开发",
        },
        {
          name: "深耕行业",
          icon: "icon-goutong_quxiao",
          remark:
            "墨砾的创始团队深耕非金属矿行业多年，与业内各专业机构（苏非院、石墨联盟等）保持密切的合作关系",
        },
        {
          name: "共同研发",
          icon: "icon-biaoqianlan_huoban",
          remark:
            "凭借团队强大的研发和生产能力，墨砾与下游客户联系紧密，共同开发更高性能的石墨负极材料",
        },
        {
          name: "新品拓展",
          icon: "icon-qian",
          remark:
            "为了提高天然石墨原料的综合利用率，墨砾不断扩展横向产品，如石墨烯前驱体等高附加值材料",
        },
      ],
      visturals: [
        {
          name: "物联网组态",
          image: "vistural-pic-1.jpg",
          remark:
            "所有设备通过PLC连接入本地局域网组态系统，操作人员通过移动设备轻松开展运营工作",
        },
        {
          name: "远程监控",
          image: "vistural-pic-2.png",
          remark:
            "生产数据实时上云，确保总部工程师和工艺专家及时助力现场工作人员解决各类异常问题，保证产品出品的稳定性",
        },
        {
          name: "智能物流",
          image: "vistural-pic-3.jpg",
          remark:
            "智能搬运机器人替代人力完成样品送检工作，既降低了人力成本，又提高了检测频率，确保第一时间发现设备异常",
        },
      ],
      productlines: [
        {
          id: 1,
          numeric: 70,
          numeric_display: 0,
          non_numeric: "%",
          detail: "产品综合收率大幅提高",
        },
        {
          id: 2,
          numeric: 50,
          numeric_display: 0,
          non_numeric: "%",
          detail: "单位产品能耗大幅降低，脱离双高产业",
        },
        {
          id: 3,
          numeric: 60,
          numeric_display: 0,
          non_numeric: "%",
          detail: "自动化产线，降低人力成本",
        },
        {
          id: 4,
          numeric: 24,
          numeric2: 7,
          numeric_display: 0,
          numeric_display2: 0,
          non_numeric: " 远程指导",
          detail: "数据上云，专家全天候远程指导，保证稳定生产",
        },
        {
          id: 5,
          numeric: undefined,
          numeric_display: 0,
          non_numeric: "转产灵活",
          detail: "批次生产，灵活转产，提高生产效率",
        },
        {
          id: 6,
          numeric: undefined,
          numeric_display: 0,
          non_numeric: "良品率高",
          detail: "物料经流设备数量少，参数可调性高，易于控制良品率",
        },
      ],
      options: [
        {
          value: "zh",
          label: "简体中文",
        },
        {
          value: "en",
          label: "英文",
        },
      ],
      value: localStorage.lang || "zh",
    };
  },
  created() {
    // gsap.to(this.$data, { duration: 0.5, tweenedNumber: 500 });

    this.bannerH = screen.availHeight;

    // setInterval(() => {
    //   this.materialActive = !this.materialActive;
    // }, 2000);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    scrollToTop() {
      // var scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      // console.log(scrollTop);
      // console.log(this.$refs.deviceTitle.getBoundingClientRect())
      // if (scrollTop>150){
      //   this.section_device_animate =true;
      // }
      // if (this.isFlexible) {
      //   if (scrollTop >= 600) this.show = true;
      //   else this.show = false;
      // }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    bannerChange(now, pre) {
      this.picList[pre].isActive = false;
      setTimeout(() => {
        this.picList[now].isActive = true;
      }, 500);
    },
    changeLanguage(lang) {
      console.log(lang);
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
    raiseNumeric(entry) {
      if (entry.target.className.indexOf("productline-info-title") >= 0) {
        console.log(123);
        this.productlines.map((pl) => {
          if (pl.numeric) {
            gsap.to(pl, { duration: 3, numeric_display: pl.numeric });
          }
          if (pl.numeric2) {
            gsap.to(pl, { duration: 3, numeric_display2: pl.numeric2 });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #47a6db;
$main-bgcolor: #1a1a1a;
$font-color: #fff;
$font-default: 16px;
$line-height-default: 24px;
$font-title: 40px;
$font-subtitle: 16px;
$font-detail-title: 20px;
$font-detail-subtitle: 16px;
$margin-bottom-default: 10px;
.home {
  color: $font-color;
  @keyframes fadeInLeft {
    from {
      transform: translateX(-12%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes fadeInRight {
    from {
      transform: translateX(12%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes fadeInLeftLong {
    from {
      transform: translateX(-15%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes fadeInBottom {
    from {
      transform: translateY(12%);
    }
    to {
      transform: translateY(0%);
    }
  }
  @keyframes fadeInTop {
    from {
      transform: translateY(-12%);
    }
    to {
      transform: translateY(0%);
    }
  }
  @keyframes mfadeInLeft {
    from {
      transform: translateX(-3%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes mfadeInRight {
    from {
      transform: translateX(3%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes mfadeInLeftLong {
    from {
      transform: translateX(-5%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes mfadeInBottom {
    from {
      transform: translateY(3%);
    }
    to {
      transform: translateY(0%);
    }
  }
  @keyframes mfadeInTop {
    from {
      transform: translateY(-3%);
    }
    to {
      transform: translateY(0%);
    }
  }
  @keyframes material-hover {
    from {
      background: rgba(24, 24, 24, 0);
    }
    to {
      background: rgba(24, 24, 24, 0.5);
      border: solid 2px $main-color;
      transform: scale(0.5);
    }
  }
  .wrapper {
    // .animate-fideInLeft {
    //   animation: fadeInLeft 1s;
    // }
    .banner {
      .el-image::after {
        content: " ";
        background-image: url("../assets/gridtile.png");
        background-repeat: repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 3;
      }
    }
    .title {
      font-size: $font-title;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 5px;
      text-align: center;
      .line {
        position: relative;
      }
      .line::after {
        content: " ";
        position: absolute;
        width: 100px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid $main-color;
      }
    }

    .subtitle {
      margin: 40px 0;
      line-height: $line-height-default;
      letter-spacing: 2px;
      font-size: $font-subtitle;
      visibility: visible;
    }
    .slogan {
      width: 100%;
      position: relative;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0);
      .slogan-content {
        @media only screen and (max-width: 767px) {
          left: 0;
          transform: translate(0, -50%);
        }
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        opacity: 1;
        color: #fff;
        .slogan-title {
          @media only screen and (max-width: 1200px) {
            // line-height: normal;
            // font-size: $font-title;
            padding: 0;
          }

          margin: 10px;
          padding: 20px;
          letter-spacing: 6px;

          visibility: visible;
          .line::after {
            @media only screen and (max-width: 767px) {
              width: 45%;
            }
            width: 25%;
          }

          p {
            @media only screen and (max-width: 1200px) {
              line-height: normal;
              font-size: $font-title;
              padding: 0;
              display:inline-flex;
            }
            display: inline-block;
            line-height: 72px;
            font-size: 72px;
            margin-left: 36px;
          }
        }
        .slogan-subtitle {
          @media only screen and (max-width: 1200px) {
            // line-height: 12px;
            // font-size: 12px;
            padding: 20px 20px;
          }
          margin: 40px 0;
          padding: 20px 60px;
          line-height: $line-height-default;
          letter-spacing: 2px;

          font-size: $font-subtitle;
          visibility: visible;
        }
      }
      .slogan-enter-active,
      .slogan-leave-active {
        transition: opacity 0.5s ease;
      }
      .slogan-enter-from,
      .slogan-leave-to {
        opacity: 0;
      }
    }
    .section-dark {
      width: 100%;
      background-color: $main-bgcolor;
      color: #fff;
      position: relative;
      z-index: 10;
      padding: 90px 0;
    }
    .device {
      @media (min-width: 768px) {
        width: 750px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      box-sizing: border-box;
      width: 100%;
      position: relative;
      z-index: 10;
      background-color: $main-bgcolor;
      margin-left: auto;
      margin-right: auto;
      padding-right: 15px;
      padding-left: 15px;
      .device-title {
        padding-bottom: 20px;
        font-size: $font-title;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
      }
      .is-inViewport {
        @media only screen and (max-width: 767px) {
          animation: mfadeInLeft 1s;
        }
        animation: fadeInLeft 1s;
      }

      .device-subtitle {
        margin: 40px 0;
        line-height: $line-height-default;
        padding: 20px 60px;
        letter-spacing: 2px;
        font-size: $font-subtitle;
        visibility: visible;
      }
      .device-detail-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .device-detail:nth-child(odd).is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInLeft 1s;
          }
          animation: fadeInLeft 1s;
        }
        .device-detail:nth-child(even).is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeRight 1s;
          }
          animation: fadeInRight 1s;
        }
        .device-detail {
          @media (min-width: 992px) {
            width: 50%;
          }
          margin-bottom: 30px;
          position: relative;
          min-height: 1px;
          padding-right: 15px;
          padding-left: 15px;
          box-sizing: border-box;
          .inner {
            @media only screen and (max-width: 767px) {
              padding: 15px;
            }
            padding: 30px;
            background-color: #1e1e1e;
            // background-color: green;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: all 0.3s;
            .el-icon {
              border: solid 2px $main-color;
              color: $main-color;
              border-radius: 100%;
              font-size: 36px;
              padding: 5px;
              transition: all 0.3s;
            }
            .iconfont {
              color: $main-color;
              border: solid 2px $main-color;
              padding: 5px;
              border-radius: 100%;
              font-size: 35px;
              line-height: 35px;
              transition: all 0.3s;
            }
            .device-detail-content {
              @media (min-width: 992px) {
                width: 350px;
              }
              padding-left: 25px;
              text-align: left;

              .device-detail-title {
                font-size: $font-detail-title;
                margin-bottom: $margin-bottom-default;
                line-height: $line-height-default;
              }
              .device-detail-subtitle {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: $font-detail-subtitle;
                line-height: $line-height-default;
              }
            }
          }
        }
        .device-detail:hover {
          .inner {
            background-color: #1b1b1b;
            .iconfont {
              border: solid 2px #fff;
              color: #fff;
            }
          }
        }
      }
    }
    .material {
      @media (min-width: 768px) {
        width: 750px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      position: relative;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      // background-color: $main-bgcolor;
      .material-title {
        margin-top: 60px;
        // animation: fadeInLeft 1s;
        font-size: $font-title;
      }
      .material-title.is-inViewport {
        @media only screen and (max-width: 767px) {
          animation: mfadeInLeft 1s;
        }
        animation: fadeInLeft 1s;
      }
      .material-detail-wrapper {
        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }
        display: flex;
        flex-direction: row;
        padding: 60px 0;
        align-items: center;
        .material-detail-image {
          // width: 400px;
          // height: 400px;
          padding: 20px;
        }
        .material-detail-image.is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInLeftLong 1s;
          }
          animation: fadeInLeftLong 1s;
        }

        .material-detail {
          @media only screen and (max-width: 767px) {
            margin-left: 0;
          }
          // @media (min-width: 768px) {
          //   width: 750px;
          // }
          // @media (min-width: 992px) {
          //   width: 970px;
          // }
          @media (min-width: 1200px) {
            width: 60%;
          }
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 75px;
          // width:100%;
          // .material-detail-content:nth-child(odd).is-inViewport {
          //   animation: fadeInLeft 1s;
          // }
          // .material-detail-content:nth-child(even).is-inViewport {
          //   animation: fadeInRight 1s;
          // }
          .material-detail-content:nth-child(4n + 1).is-inViewport {
            @media only screen and (max-width: 767px) {
              animation: mfadeInLeft 1s;
            }
            animation: fadeInLeft 1s;
          }
          .material-detail-content:nth-child(4n + 2).is-inViewport {
            @media only screen and (max-width: 767px) {
              animation: mfadeInTop 1s;
            }
            animation: fadeInTop 1s;
          }
          .material-detail-content:nth-child(4n + 3).is-inViewport {
            @media only screen and (max-width: 767px) {
              animation: mfadeInBottom 1s;
            }
            animation: fadeInBottom 1s;
          }
          .material-detail-content:nth-child(4n + 4).is-inViewport {
            @media only screen and (max-width: 767px) {
              animation: mfadeInRight 1s;
            }
            animation: fadeInRight 1s;
          }
          .material-detail-content {
            @media (min-width: 992px) {
              width: 50%;
            }
            // width:300px;
            position: relative;
            padding: 15px 15px;
            text-align: left;
            box-sizing: border-box;
            .material-detail-content-small {
              border: solid 2px rgba(0, 0, 0, 0);
              transition: all 0.2s ease-in-out;
              padding: 20px;
              .material-detail-icon {
                font-size: 40px;
                margin-bottom: 10px;
                .iconfont {
                  font-size: 40px;
                }
              }
              .material-detail-title {
                font-size: $font-detail-title;
                margin-bottom: $margin-bottom-default;
              }
              .material-detail-subtitle {
                height: 70px;
                line-height: $line-height-default;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 10px;
                font-size: $font-detail-subtitle;
              }
            }
            .material-detail-content-small:hover {
              background: rgba(24, 24, 24, 0.2);
              border: solid 2px $main-color;
              // animation: material-hover 1s;
              // animation-fill-mode:both;
              // transform-origin: left center;
              transform: scale(1.05);
              .material-detail-icon {
                color: $main-color;
              }
            }
          }
        }
      }
    }
    .vistural {
      @media (min-width: 768px) {
        width: 700px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      position: relative;
      z-index: 10;
      background-color: $main-bgcolor;
      margin-left: auto;
      margin-right: auto;
      .vistural-title {
        padding: 60px 0 20px;
        font-size: $font-title;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
      }
      .vistural-title.is-inViewport {
        animation: fadeInLeft 1s;
      }

      .vistural-subtitle {
        margin: 40px 0;
        padding: 20px 60px;
        line-height: $line-height-default;
        letter-spacing: 2px;
        font-size: $font-subtitle;
        visibility: visible;
      }
      .vistural-detail-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // width: 100%;
        justify-content: center;
        padding: 0 10px 60px;
        margin-left: auto;
        margin-right: auto;
        .vistural-detail:nth-child(3n + 1).is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInLeft 1s;
          }
          animation: fadeInLeft 1s;
        }
        .vistural-detail:nth-child(3n + 2).is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInBottom 1s;
          }
          animation: fadeInBottom 1s;
        }
        .vistural-detail:nth-child(3n + 3).is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInRight 1s;
          }
          animation: fadeInRight 1s;
        }
        .vistural-detail {
          @media (min-width: 992px) {
            width: 33.33333%;
          }
          // padding: 0 15px;
          // margin-left: 10px;
          // margin-right: 10px;
          box-sizing: border-box;
          .inner {
            margin-bottom: 30px;
            padding: 20px;
            // background-color: #1e1e1e;
            // background-color: green;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .vistural-detail-content {
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
              @media (min-width: 992px) {
                width: 350px;
              }
              // padding-left: 25px;
              text-align: left;
              // width: 350px;
              .vistural-detail-title {
                margin-top: $margin-bottom-default;
                font-size: $font-detail-title;
                margin-bottom: $margin-bottom-default;
                line-height: $line-height-default;
              }
              .vistural-detail-subtitle {
                width: 100%;
                // height: 70px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: $font-detail-subtitle;
                line-height: $line-height-default;
              }
            }
          }
        }
      }
    }
    .productline {
      @media (min-width: 768px) {
        width: 700px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      position: relative;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      padding: 90px 30px;
      .productline-title {
        p {
          font-size: 30px;
          border: solid 2px #fff;
          display: inline-block;
          letter-spacing: 2px;
          text-transform: uppercase;
          padding: 5px 10px 5px 10px;
        }
      }
      .productline-title.is-inViewport {
        animation: fadeInLeft 1s;
      }
      .productline-detail-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // width: 100%;
        justify-content: center;
        // padding: 0 10px 60px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        .productline-detail {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
          @media (min-width: 992px) {
            width: 33.33333%;
          }

          margin-bottom: 30px;

          .inner {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            .el-icon {
              color: $main-color;
              font-size: 24px;
            }
            .productline-info {
              margin-left: 20px;
              text-align: left;
              .productline-info-title {
                font-size: $font-detail-title;
                font-family: "Raleway", Arial, Helvetica, sans-serif;
                margin-bottom: $margin-bottom-default;
                line-height: $line-height-default;
              }
              .productline-info-detail {
                font-size: $font-detail-subtitle;
                line-height: $line-height-default;
              }
            }
          }
        }
        .productline-detail.is-inViewport {
          @media only screen and (max-width: 767px) {
            animation: mfadeInLeft 1s;
          }
          animation: fadeInLeft 1s;
        }
      }
    }
    .footer {
      @media (min-width: 768px) {
        width: 700px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
      position: relative;
      z-index: 10;
      // margin-left: auto;
      margin: 0 auto;
      padding: 90px 0;
      .footer-link {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.75);
        .footer-link-nav {
          @media only screen and (max-width: 767px) {
            flex-direction: column;
          }
          display: flex;
          flex-direction: row;
          // margin: auto 0;
          .footer-link-logo {
            // display: inline-block;
            margin-right: 40px;
            .el-image {
              // width:42px;
              height: 24px;
              transition: all 0.3s;
              opacity: 0.75;
            }
            .el-image:hover {
              opacity: 1;
            }
          }
          .footer-link-menu {
            // display: inline-block;
            font-weight: 400;
            vertical-align: baseline;

            .footer-link-menu-item {
              display: inline-block;
              padding: 0 12px;
              // padding-right: 24px;
              height: 24px;
              transition: all 0.3s;
              opacity: 0.75;
              cursor: pointer;
            }
            .footer-link-menu-item:hover {
              opacity: 1;
            }
          }
        }
        .footer-link-external {
          display: flex;
          align-items: center;
          .footer-link-external-item {
            padding: 0 12px;
            height: 24px;
            cursor: pointer;
            .el-icon {
              font-size: 24px;
            }
          }
        }
      }
      .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-copyright {
          display: flex;
          // font-size: 16px;
          color: rgba(255, 255, 255, 0.75);
          .copyright {
            padding-right: 16px;
          }
        }
        .footer-language {
          position: relative;
          display: flex;
          align-items: center;
          // height: 24px;
          padding: 0 8px;
          border-radius: 4px;
          // transition: all 0.3s;
          cursor: pointer;
          .el-select {
            margin-left: 20px;
            width: 120px;
          }
        }
        // .footer-language:hover {
        //   background-color: rgba(255, 255, 255, 0.09);
        //   border-radius: 4px;
        // }
      }
    }
    .test {
      position: relative;
      z-index: 10;
    }
  }
}
</style>

<style lang="scss">
.home {
  .el-carousel__item.is-animating {
    transition: transform 0.8s ease-in-out;
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    // height: 1000px;
    width: 100%;
    z-index: 1;
  }
}
</style>
